import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import MainContainer from '../components/MainContainer';
import WhyChooseUs from '../components/WhyChooseUs';
import ClientReviews from '../components/ClientReviews';
import {servicesPageText, mobilesMockupLottie} from './../commonvariables';
import {Box} from '@mui/material';

const ServicesPage = () => {
  return (
    <Box sx={{backgroundColor: '#E3DFFD'}}>
      <Navbar />
      <MainContainer
        gridContainerStyle={{
          backgroundColor: '#9D3C72',
          alignItems: 'center',
          borderRadius: '0 0 150% 0',
          paddingTop: {xs: '15px', md: 0},
        }}
        pageText={servicesPageText}
        lottie={mobilesMockupLottie}
      />
      <WhyChooseUs />
      <ClientReviews />
      <Footer />
    </Box>
  );
};

export default ServicesPage;
