import React from 'react';
import {Box} from '@mui/material';
import Text from './Text';

const Benefit = ({icon, texts}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '40px',
        flexDirection: 'column',
      }}
    >
      {icon}
      {texts.map((text) => {
        return (
          <Text
            variant={text.variant}
            component={text.component}
            text={text.text}
            style={text.style}
            key={text.text}
          />
        );
      })}
    </Box>
  );
};

export default Benefit;
