import React from 'react';
import Image from './Image';
import {Grid} from '@mui/material';
import {clientageLogo, clientageText} from '../commonvariables';
import Text from './Text';

const Clientage = () => {
  return (
    <Grid
      container
      sx={{
        marginY: {xs: '30px', md: '55px'},
        display: 'flex',
        alignItems: 'center',
        flexDirection: {xs: 'column', sm: 'row'},
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12} md={3}>
        <Text
          variant="p"
          component="div"
          text={clientageText.text}
          style={clientageText.style}
        />
      </Grid>
      {clientageLogo.map((logo, index) => {
        return (
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            key={index === 0 ? 'AS FEATURED ON:' : logo.name}
            sx={{display: 'flex', justifyContent: 'center', marginY: '25px'}}
          >
            <Image source={logo.source} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Clientage;
