import React from 'react';
import {Grid, Box, Divider} from '@mui/material';
import Image from './Image';
import Text from './Text';
import {copyrightText, footerAddressText, logo} from './../commonvariables';
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import InfoIcon from '@mui/icons-material/Info';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import CampaignIcon from '@mui/icons-material/Campaign';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';

const Footer = () => {
  return (
    <Grid
      container
      sx={{
        paddingBottom: '25px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: {xs: 'center', sm: 'left'},
        alignItems: 'center',
        backgroundColor: '#37306B',
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: {xs: '30px', sm: 0},
        }}
      >
        <Image source={logo} alt="logo" height="100" />
      </Grid>
      <Grid item xs={12} sm={6} md={3} sx={{justifyContent: {xs: 'center'}}}>
        <Text
          text="Quick Links"
          variant="p"
          component="div"
          style={{
            fontSize: '35px',
            color: '#fff',
            fontWeight: 700,
            marginTop: '20px',
          }}
        />
        <Link to="/" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <HomeIcon />
            <Text
              text="Home"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/portfolio" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <BusinessCenterIcon />
            <Text
              text="Portfolio"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <SettingsSuggestIcon />
            <Text
              text="Services"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/aboutUs" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <InfoIcon />
            <Text
              text="About Us"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Text
          text="Services"
          variant="p"
          component="div"
          style={{
            fontSize: '35px',
            color: '#fff',
            fontWeight: 700,
            marginTop: '20px',
            marginLeft: {sm: '20px', md: 0},
          }}
        />
        <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <MobileFriendlyIcon />
            <Text
              text="Unparalleled Mobile Apps"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <CampaignIcon />
            <Text
              text="Social Media Marketing"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <DesignServicesIcon />
            <Text
              text="Graphic Design Services"
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
        <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
          <Box sx={{marginTop: '20px', display: 'flex', marginLeft: '20px'}}>
            <Text
              text="More..."
              variant="p"
              component="div"
              style={{marginLeft: '10px'}}
            />
          </Box>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Text
          text="Contact Info"
          variant="p"
          component="div"
          style={{
            fontSize: '35px',
            color: '#fff',
            fontWeight: 700,
            marginTop: {xs: '20px', sm: 0},
          }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            marginLeft: '20px',
          }}
        >
          <LocationOnIcon style={{color: '#fff'}} />
          <Text
            text={footerAddressText.text}
            variant="p"
            component="div"
            style={{
              color: '#fff',
              marginLeft: '10px',
              width: '300px',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            marginLeft: '20px',
          }}
        >
          <EmailIcon style={{color: '#fff'}} />
          <Text
            text="support@tecknovation.com"
            variant="p"
            component="div"
            style={{
              color: '#fff',
              marginLeft: '10px',
              width: '300px',
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '20px',
            marginLeft: '20px',
          }}
        >
          <CallIcon style={{color: '#fff'}} />
          <Text
            text="+923024168275"
            variant="p"
            component="div"
            style={{
              color: '#fff',
              marginLeft: '10px',
              width: '300px',
            }}
          />
        </Box>
      </Grid>
      <Divider
        style={{
          backgroundColor: '#fff',
          padding: '0.1px',
          width: '90%',
          marginTop: '30px',
        }}
      />
      <Grid item xs={12} sm={6} md={3} sx={{marginTop: {xs: '10px'}}}>
        <Text
          text={copyrightText.text}
          variant={copyrightText.variant}
          component={copyrightText.component}
          style={copyrightText.style}
        />
      </Grid>
    </Grid>
  );
};

export default Footer;
