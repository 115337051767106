import React from 'react';

const Image = ({source, height, width, alt, style}) => {
  return (
    <img
      src={source}
      height={height && height}
      width={width && width}
      alt={alt}
      style={style}
    />
  );
};

export default Image;
