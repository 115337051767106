import React from 'react';
import {Box} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';

const FiveStarRating = ({style}) => {
  return (
    <Box sx={style && style}>
      <StarRateIcon style={{color: '#fff'}} />
      <StarRateIcon style={{color: '#fff'}} />
      <StarRateIcon style={{color: '#fff'}} />
      <StarRateIcon style={{color: '#fff'}} />
      <StarRateIcon style={{color: '#fff'}} />
    </Box>
  );
};

export default FiveStarRating;
