import React from 'react';
import {Button, Card} from '@mui/material';
import {Link} from 'react-router-dom';
import Text from './Text';
import ShopIcon from '@mui/icons-material/Shop';
import LanguageIcon from '@mui/icons-material/Language';

const SingleCard = ({heading, description, link, buttonText, type}) => {
  return (
    <Card
      sx={{
        borderRadius: '15px',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
      }}
    >
      <Text
        text={heading}
        style={{
          fontWeight: 700,
          fontSize: '20px',
          textAlign: 'center',
          margin: '25px',
        }}
      />
      <Text
        text={description}
        style={{
          fontSize: '17px',
          marginX: '25px',
          marginBottom: '25px',
          textAlign: 'center',
        }}
      />
      <Link
        to={link}
        style={{
          textDecoration: 'none',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant="contained"
          size="small"
          color="info"
          sx={{marginY: '25px'}}
        >
          {type === 'app' ? (
            <ShopIcon style={{marginRight: '15px'}} />
          ) : (
            <LanguageIcon style={{marginRight: '15px'}} />
          )}
          {buttonText}
        </Button>
      </Link>
    </Card>
  );
};

export default SingleCard;
