import {TextField} from '@mui/material';
import React from 'react';

const TextInput = ({name, setState, label}) => {
  return (
    <TextField
      variant="standard"
      required
      name={name}
      onChange={(e) => setState(e.target.value)}
      label={label}
      fullWidth
    />
  );
};

export default TextInput;
