import React from 'react';
import {Grid} from '@mui/material';
import Text from './../components/Text';
import LottiePlayer from '../components/LottiePlayer';

const MainContainer = ({gridContainerStyle, pageText, lottie}) => {
  return (
    <Grid container sx={gridContainerStyle}>
      <Grid item md={1} />
      <Grid item xs={12} md={5}>
        {pageText.map((text) => {
          return (
            <Text
              variant={text.variant}
              component={text.component}
              text={text.text}
              style={text.style}
              key={text.text}
            />
          );
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {lottie && <LottiePlayer lottie={lottie} />}
      </Grid>
    </Grid>
  );
};

export default MainContainer;
