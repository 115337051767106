import React from 'react';
import Clientage from '../components/Clientage';
import MoreInfo from './../components/MoreInfo';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import MainContainer from '../components/MainContainer';
import {homePageText, mainLottie} from './../commonvariables';

const HomePage = () => {
  return (
    <>
      <Navbar />
      <MainContainer
        gridContainerStyle={{
          backgroundColor: '#9D3C72',
          alignItems: 'center',
          borderRadius: '0 0 50px 50px',
          paddingTop: {xs: '15px', md: 0},
        }}
        pageText={homePageText}
        lottie={mainLottie}
      />
      <Clientage />
      <MoreInfo />
      <Footer />
    </>
  );
};

export default HomePage;
