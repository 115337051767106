import React from 'react';
import {Typography} from '@mui/material';

const Text = ({variant, component, text, style}) => {
  return (
    <Typography variant={variant} component={component} sx={style && style}>
      {text}
    </Typography>
  );
};

export default Text;
