import React, {useEffect, useState} from 'react';
import {Box, Button, Grid} from '@mui/material';
import Text from './Text';
import TextInput from './TextInput';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const ContactForm = () => {
  const serviceId = process.env.REACT_APP_serviceId;
  const templateId = process.env.REACT_APP_templateId;
  const publicKey = process.env.REACT_APP_publicKey;
  const [firstName, setFirstName] = useState();
  const [firstNameErr, setFirstNameErr] = useState();
  const [lastName, setLastName] = useState();
  const [lastNameErr, setLastNameErr] = useState();
  const [email, setEmail] = useState();
  const [emailErr, setEmailErr] = useState();
  const [message, setMessage] = useState();
  const [messageErr, setMessageErr] = useState();
  const templateParams = {
    name: `${firstName} ${lastName}`,
    email,
    message,
  };
  useEffect(() => {
    if (firstNameErr) {
      setFirstNameErr('First name is required!');
    }
    if (lastNameErr) {
      setLastNameErr(lastName ? '' : 'Last name is required!');
    }
    if (emailErr) {
      setEmailErr(email ? '' : 'Email is required!');
    }
    if (messageErr) {
      setMessageErr(message ? '' : 'Message is required!');
    }
  }, [firstName, lastName, email, message]);
  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!firstName) {
      setFirstNameErr('First name is required!');
    }
    if (!lastName) {
      setLastNameErr('Last name is required!');
    }
    if (!email) {
      setEmailErr('Email is required!');
    }
    if (email && !emailRegex.test(email)) {
      setEmailErr('Email is not valid!');
    }
    if (!message) {
      setMessageErr('Message is required!');
    }
    if (firstName && lastName && email && emailRegex.test(email) && message) {
      emailjs.send(serviceId, templateId, templateParams, publicKey).then(
        (response) => {
          Swal.fire({
            title: 'Success!',
            icon: 'success',
            html: 'Message successfully sent!',
          });
        },
        (err) => {
          Swal.fire({
            title: 'Error!',
            html: 'Failed to send message!',
            icon: 'error',
          });
        }
      );
    }
  };
  return (
    <Grid container sx={{height: '100%'}}>
      <Box
        sx={{
          height: {xs: '80%', md: '100%'},
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: {xs: '30px', md: 0},
        }}
      >
        <Grid item xs={12} sx={{maxHeight: '75px'}}>
          <Text
            text="Get in touch"
            style={{
              fontSize: '34px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          />
        </Grid>
        <Box sx={{justifyContent: 'space-between', width: {md: '800px'}}}>
          <Grid container sx={{justifyContent: 'space-between'}}>
            <Grid item xs={12} sm={5}>
              <TextInput
                name="firstName"
                setState={setFirstName}
                label="First Name"
              />
              <Text
                text={firstNameErr}
                style={{color: 'red', fontSize: '12px'}}
              />
            </Grid>
            <Grid item xs={12} sm={5} sx={{marginTop: {xs: '20px', sm: 0}}}>
              <TextInput
                name="lastName"
                setState={setLastName}
                label="Last Name"
              />
              <Text
                text={lastNameErr}
                style={{color: 'red', fontSize: '12px'}}
              />
            </Grid>
            <Grid item xs={12} sm={5} sx={{marginTop: {xs: '20px'}}}>
              <TextInput name="email" setState={setEmail} label="Email" />{' '}
              <Text text={emailErr} style={{color: 'red', fontSize: '12px'}} />
            </Grid>
            <Grid item xs={12} sm={5} sx={{marginTop: {xs: '20px'}}}>
              <TextInput name="message" setState={setMessage} label="Message" />
              <Text
                text={messageErr}
                style={{color: 'red', fontSize: '12px'}}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: {xs: '20px'},
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={handleSubmit}
                // disabled={isButtonDisabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default ContactForm;
