import {createBrowserRouter} from 'react-router-dom';
import HomePage from './pages/HomePage';
import ErrorPage from './components/ErrorPage';
import PortfolioPage from './pages/PortfolioPage';
import ServicesPage from './pages/ServicesPage';
import AboutUsPage from './pages/AboutUsPage';
import ContactUsPage from './pages/ContactUsPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/portfolio',
    element: <PortfolioPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/services',
    element: <ServicesPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/aboutUs',
    element: <AboutUsPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/contactUs',
    element: <ContactUsPage />,
    errorElement: <ErrorPage />,
  },
]);
