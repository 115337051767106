import React from 'react';
import {Grid} from '@mui/material';
import LottiePlayer from './LottiePlayer';
import {pageNotFoundLottie} from '../commonvariables';

const ErrorPage = () => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <Grid item>
        <LottiePlayer lottie={pageNotFoundLottie} />
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
