import React from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from './Router';
import {Fab, Link} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <Link href="https://wa.me/+923024168275">
        <Fab
          aria-label="add"
          sx={{
            position: 'fixed',
            bottom: '25px',
            right: '25px',
            backgroundColor: '#54B435',
            color: '#fff',
          }}
        >
          <WhatsAppIcon />
        </Fab>
      </Link>
    </>
  );
};

export default App;
