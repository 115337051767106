import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ContactForm from './../components/ContactForm';
import {Box} from '@mui/material';

const ContactUsPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100vh',
      }}
    >
      <Navbar />
      <ContactForm />
      <Footer />
    </Box>
  );
};

export default ContactUsPage;
