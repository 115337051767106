import React from 'react';
import Navbar from '../components/Navbar';
import MainContainer from '../components/MainContainer';
import Footer from '../components/Footer';
import {aboutUsPageText, aboutLottie} from './../commonvariables';
import Vision from './../components/Vision';

const AboutUsPage = () => {
  return (
    <>
      <Navbar />
      <MainContainer
        gridContainerStyle={{
          alignItems: 'center',
          borderRadius: '0 0 150% 0',
          paddingTop: {xs: '15px', md: 0},
        }}
        pageText={aboutUsPageText}
        lottie={aboutLottie}
      />
      <Vision />
      <Footer />
    </>
  );
};

export default AboutUsPage;
