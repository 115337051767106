import React from 'react';
import {Grid} from '@mui/material';
import {benefitText, whyChooseUsText} from '../commonvariables';
import Text from './Text';
import Benefit from './Benefit';

const WhyChooseUs = () => {
  return (
    <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
      <Grid item md={1} />
      <Grid item xs={12} md={5} sx={{paddingY: '125px'}}>
        {whyChooseUsText.map((text) => {
          return (
            <Text
              variant={text.variant}
              component={text.component}
              text={text.text}
              style={text.style}
              key={text.text}
            />
          );
        })}
      </Grid>
      <Grid item xs={12} md={6} />
      {benefitText.map((text) => {
        return (
          <Grid
            item
            xs={10}
            sm={5}
            md={3}
            sx={{margin: '15px'}}
            key={text.icon}
          >
            <Benefit icon={text.icon} texts={text.texts} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default WhyChooseUs;
