import React from 'react';
import {Grid} from '@mui/material';
import LottiePlayer from '../components/LottiePlayer';
import Text from './Text';
import {contactText, contactUsLottie} from '../commonvariables';

const Vision = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#3E54AC',
        alignItems: 'center',
        paddingBottom: '45px',
        borderRadius: '50px 50px 0 0',
      }}
    >
      <Grid item xs={12} md={6}>
        <LottiePlayer lottie={contactUsLottie} />
      </Grid>
      <Grid item xs={12} md={5}>
        {contactText.map((text) => {
          return (
            <Text
              variant={text.variant}
              component={text.component}
              text={text.text}
              style={text.style}
              key={text.text}
            />
          );
        })}
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
};

export default Vision;
