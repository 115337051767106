import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardsContainer from './../components/CardsContainer';
import MainContainer from '../components/MainContainer';
import {portfolioPageText} from '../commonvariables';

const PortfolioPage = () => {
  return (
    <>
      <Navbar />
      <MainContainer
        gridContainerStyle={{
          backgroundColor: '#9D3C72',
          alignItems: 'center',
          paddingTop: {xs: '15px', md: '60px'},
          paddingBottom: {xs: '55px', md: '85px'},
        }}
        pageText={portfolioPageText}
      />
      <CardsContainer />
      <Footer />
    </>
  );
};

export default PortfolioPage;
