import React from 'react';
import {Box, Divider, Grid} from '@mui/material';
import Text from './Text';
import FiveStarRating from './FiveStarRating';
import Image from './Image';
import {ratingsText} from '../commonvariables';

const ClientReviews = () => {
  return (
    <>
      <Grid
        container
        sx={{
          marginTop: '50px',
          paddingBottom: '80px',
          paddingX: {xs: '40px', md: '80px'},
          backgroundColor: '#444B51',
        }}
      >
        <Grid item md={6} />
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Text
              text="5.0"
              style={{
                fontSize: {xs: '5rem', sm: '7rem', md: '10rem'},
                fontWeight: 600,
                color: '#fff',
                textAlign: 'center',
              }}
            />
          </Box>
        </Grid>
        {ratingsText.map((text, index) => {
          return (
            <>
              <Grid
                item
                xs={12}
                sm={5}
                sx={{marginTop: '45px'}}
                key={text.clientName}
              >
                <Divider sx={{backgroundColor: '#fff'}} />
                <FiveStarRating
                  style={{
                    margin: '55px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                />
                <Text
                  text={text.clientFeedback}
                  style={{
                    color: '#fff',
                    textAlign: 'center',
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '55px',
                  }}
                >
                  <Image
                    source={text.clientPicture}
                    style={{borderRadius: '50%'}}
                    height="50px"
                    width="50px"
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginLeft: '25px ',
                    }}
                  >
                    <Text text={text.clientName} style={{color: '#fff'}} />
                    <Text
                      text={text.clientDesignation}
                      style={{color: '#fff'}}
                    />
                  </Box>
                </Box>
              </Grid>
              {index % 2 === 1 ? <></> : <Grid sm={2} />}
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default ClientReviews;
