import logo1 from './assets/images/tech-startup-featured-on-logo-1.png';
import logo2 from './assets/images/tech-startup-featured-on-logo-2.png';
import logo3 from './assets/images/tech-startup-featured-on-logo-3.png';
import logo4 from './assets/images/tech-startup-featured-on-logo-4.png';
import softwareDevelopmentLottie from './assets/lottie-files/72856-software-development.json';
import appDevelopmentLottie from './assets/lottie-files/127139-app-development.json';
import tecknovationLogo from './assets/images/tecknovationLogo.svg';
import notfoundLottie from './assets/lottie-files/59109-404-error-oops-page-not-found.json';
import mobilesLottie from './assets/lottie-files/45486-android-and-iphone.json';
import aboutUsLottie from './assets/lottie-files/84565-about-us.json';
import contactLottie from './assets/lottie-files/116054-contact.json';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import CampaignIcon from '@mui/icons-material/Campaign';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import TrafficIcon from '@mui/icons-material/Traffic';
import CloudIcon from '@mui/icons-material/Cloud';

const currentYear = new Date().getFullYear();

export const logo = tecknovationLogo;

export const mainLottie = softwareDevelopmentLottie;
export const infoLottie = appDevelopmentLottie;
export const pageNotFoundLottie = notfoundLottie;
export const mobilesMockupLottie = mobilesLottie;
export const aboutLottie = aboutUsLottie;
export const contactUsLottie = contactLottie;

export const homePageText = [
  {
    text: 'We Provide Digital Solution',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: {xs: 'center', md: 'left'},
      fontSize: {xs: '37px', md: '57px'},
      fontWeight: '700',
      marginBottom: '25px',
    },
  },
  {
    text: 'Founded in 2008, Tecknovation was built on the vision of delivering high performance technology solutions that redefine and streamline the way financial institutions, financial professionals and their clients transact business. While we’ve grown to become a major force in the industry, we’ve been careful to retain what got us there: A relentless dedication to finding new ways to help financial institutions reach their goals, manage their businesses more efficiently, and generate new revenue. We have continued steady growth to become a leading financial technology provider to some of the most prestigious firms in the industry.',
    variant: 'p',
    component: 'div',
    style: {color: '#fff', textAlign: 'left', margin: {xs: '15px', md: 0}},
  },
];

export const clientageLogo = [
  {
    name: 'logo1',
    source: logo1,
  },
  {
    name: 'logo2',
    source: logo2,
  },
  {
    name: 'logo3',
    source: logo3,
  },
  {
    name: 'logo4',
    source: logo4,
  },
];

export const clientageText = {
  text: 'AS FEATURED ON:',
  style: {
    textAlign: 'center',
    marginY: {xs: '25px', md: 0},
    fontWeight: 700,
    fontSize: {xs: '22px', md: '18px'},
  },
};

export const moreInfoText = [
  {
    text: 'Dedicate yourself to the things  that really matter',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: 'center',
      fontSize: {xs: '27px', md: '37px'},
      fontWeight: '700',
      padding: '15px',
    },
  },
  {
    text: 'Tecknovation performs thorough research on each given problem and advises its customers on how their business growth aims can be achieved by the implementation of a specific and research-based software solution.',
    variant: 'p',
    component: 'div',
    style: {color: '#fff', textAlign: 'center', margin: {xs: '15px', md: 0}},
  },
];

export const portfolioPageText = [
  {
    text: 'Our Great Achievements',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: {xs: 'center', md: 'left'},
      fontSize: {xs: '37px', md: '57px'},
      fontWeight: '700',
      marginBottom: '25px',
    },
  },
  {
    text: 'Tecknovation performs thorough research on each given problem and advises its customers on how their business growth aims can be achieved by the implementation of a specific and research-based software solution.',
    variant: 'p',
    component: 'div',
    style: {color: '#fff', textAlign: 'left', margin: {xs: '15px', md: 0}},
  },
];

export const cardsText = [
  {
    heading: 'Dillon Gas',
    description:
      'Dillon Gas & Allied Chemicals Limited – Established to become the ‘go to’ Liquified Petroleum Gas (LPG) distribution and supply company with affordable prices for households, retailers, industries, hotels and restaurants. ',
    link: 'https://play.google.com/store/apps/details?id=com.dillon_gas_app',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'TestNow OnDemand AI-Powered',
    description:
      'TestNow is a product of Wheeebox. TestNow offers a safe, secured and convenient way for Students and Corporates to assess themselves on-demand.',
    link: 'https://play.google.com/store/apps/details?id=com.wheeboxapp',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Elite Cab Driver',
    description:
      'Elite Cab Driver is an application for drivers in Dallas, USA who want to make some money providing safe and reliable rides.',
    link: 'https://play.google.com/store/apps/details?id=us.elite.cab.service.dallas.texas.conductor',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Elite Cab Service in Dallas',
    description:
      'Elite cab service in Dallas, USA. Elite cab service in Dallas, USA is your app to get taxi booking service 24/7 available. Get Elite cab service - taxi booking in Dallas now or book later.',
    link: 'https://play.google.com/store/apps/details?id=us.elite.cab.service.dallas.texas.pasajero',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Wheebox Examsheet Scanner',
    description:
      'Wheebox Examsheet Scanner is designed to be used along with Wheebox Application which is suitable for Scanning QR codes on a desktop/ laptop and uploads your answer sheet on Wheebox Server.',
    link: 'https://play.google.com/store/apps/details?id=com.wheeboxexamsheetscanner',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'MyNews',
    description:
      'There are plenty of apps that will display generic broad news categories like Business, Sport, Politics and ShowBiz. But MyNews is different.',
    link: 'https://play.google.com/store/apps/details?id=com.mynews2',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Fit Wash Service Station App',
    description: 'Fit Wash Service Station App for service stations.',
    link: 'https://play.google.com/store/apps/details?id=com.esoftlogix.servicestationsadmin',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Fit Wash Service Station App',
    description: 'Fit Wash Service Station App for service stations.',
    link: 'https://play.google.com/store/apps/details?id=com.esoftlogix.servicestationsadmin',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Soft Jobs',
    description:
      'Soft Jobs is the job portal application. In which where companies will be registered and upload their jobs for the job-seekers. It can schedule interviews for the employees and update the job application status.',
    link: 'https://play.google.com/store/apps/details?id=com.gwsoft.softjobs',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Vaidik Aahaar',
    description:
      'A healthy lifestyle is the basis of life, by adopting it you can become healthy and energetic, if you look at the lifestyle of 70 or 80 years ago, our food was prepared with purity as well as without any adulteration according to the Vedic method, which is used in our homes.',
    link: 'https://play.google.com/store/apps/details?id=com.vadikmahila',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Dr. SPRGIC – Student',
    description:
      'Education helps an individual to develop his/her capacities and teachers help the student to know himself/herself and discover what he or is capable of doing.',
    link: 'https://play.google.com/store/apps/details?id=com.technosetup.student_mis',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Dr. SPRGIC – Teacher',
    description:
      'Education helps an individual to develop his/her capacities and teachers help the student to know himself/herself and discover what he or is capable of doing.',
    link: 'https://play.google.com/store/apps/details?id=com.technosetup.teacher_mis',
    buttonText: 'Google PlayStore',
    type: 'app',
  },
  {
    heading: 'Dentist Ranked',
    description:
      'We have vetted and hand-picked the best dentists in your city.',
    link: 'https://www.dentistsranked.com/',
    buttonText: 'Website Link',
    type: 'web',
  },
  {
    heading: 'Job Portal',
    description:
      'Hiredroid is a unique professional job portal for both employers and professionals based on React which offers effective hiring solutions.',
    link: 'https://hiredroid.com/',
    buttonText: 'Website Link',
    type: 'web',
  },
  {
    heading: 'Scrumdroid',
    description:
      'Scrumdroid is a unique and professional project management MERN application that offers all the features of the latest scrum management tool.',
    link: 'https://scrumdroid.com/',
    buttonText: 'Website Link',
    type: 'web',
  },
  {
    heading: 'SSDEMR',
    description:
      'SSD is a dermatology clinic. SSDEMR is a MERN application based on HIPAA compliance.',
    link: 'https://ssdemr.com/',
    buttonText: 'Website Link',
    type: 'web',
  },
  {
    heading: 'Refractio',
    description:
      'Refractio is a MERN application where users have to pay a subscription fee (through Stripe) to create a team where new users can be created or added.',
    link: 'https://54.185.166.224/',
    buttonText: 'Website Link',
    type: 'web',
  },
  {
    heading: 'Tajfeed',
    description: '',
    link: 'https://tajfeed.com/headoffice/',
    buttonText: 'Website Link',
    type: 'web',
  },
];

export const servicesPageText = [
  {
    text: 'Transforming Your Business in the Digital Age with Our Comprehensive Software Development and IT Consulting Services',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: {xs: 'center', md: 'left'},
      fontSize: {xs: '25px', md: '37px'},
      fontWeight: '700',
      marginBottom: '25px',
    },
  },
  {
    text: 'Our IT consulting services are designed to help businesses leverage technology to streamline processes, reduce costs, and improve performance. We work with our clients to identify opportunities for improvement and provide recommendations for implementing the latest technologies and best practices.At our software company, we pride ourselves on our commitment to excellence, customer satisfaction, and delivering innovative solutions that drive results. Contact us today to learn more about how we can help your business succeed in the digital age.',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: 'left',
      margin: {xs: '15px', md: 0},
    },
  },
];

export const whyChooseUsText = [
  {
    text: 'Why choose us',
    variant: 'p',
    component: 'div',
    style: {
      color: '#000',
      textAlign: {xs: 'center', md: 'left'},
      fontSize: {xs: '37px', md: '57px'},
      fontWeight: '700',
      marginBottom: '25px',
    },
  },
  {
    text: 'Our team of experts has years of experience developing high-quality software solutions for businesses of all sizes.',
    variant: 'p',
    component: 'div',
    style: {color: '#000', textAlign: 'left', margin: {xs: '15px', md: 0}},
  },
];

export const benefitText = [
  {
    icon: (
      <MobileFriendlyIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'Unparalleled Mobile Apps',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'Our application software developers are industry-specific technology experts with many years of combined experience delivering interoperable, scalable, and flexible web & mobile applications for all kinds of industries.',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
  {
    icon: (
      <CampaignIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'Social Media Marketing',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'Does your business have the votes of confidence it needs from social media networks? Build your brand awareness across the web and create meaningful connections with your customers and followers through social media marketing (SMM).',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
  {
    icon: (
      <DesignServicesIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'Graphic Design Services',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'Graphic design extends beyond establishing identity and brand recognition. It can be very effective at attracting attention and encouraging your audience to want to learn more. It also can be employed to produce visual aids that can better help you communicate your ideas.',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
  {
    icon: (
      <CodeIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'Website Design & Development Services',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'Our website developers provide expert web application development and web design services to our clients. Wolf-techs offers a variety of website design and development services, from creating mobile web development solutions and responsive website designs, to building custom e-commerce experiences using the latest and proven web technologies.',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
  {
    icon: (
      <TrafficIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'SEO Services That Grow Traffic and Increase',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'A custom SEO campaign with WebFX targets on-page and off-page SEO, which includes things like keyword research and content implementation, to help your most valuable audience find you online.',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
  {
    icon: (
      <CloudIcon
        style={{
          backgroundColor: '537FE7',
          color: '#fff',
          fontSize: '3rem',
          borderRadius: '25%',
          padding: '10px',
        }}
      />
    ),
    texts: [
      {
        text: 'Website Hosting Services',
        variant: 'p',
        component: 'div',
        style: {
          fontSize: '20px',
          fontWeight: 700,
          marginTop: '20px',
          textAlign: 'center',
        },
      },
      {
        text: 'We only provide fully managed, reliable web hosting so you can focus on taking care of your clients and building amazing websites. Our services are backed by a 100% network and power uptime and monitored 24/7.',
        variant: 'p',
        component: 'div',
        style: {fontSize: '17px', marginTop: '20px', textAlign: 'center'},
      },
    ],
  },
];

export const ratingsText = [
  {
    clientFeedback:
      '"They have very good experiences in applications development they have given us free UI and UX before start happy with their work."',
    clientPicture:
      'https://tecknovation.com/wp-content/uploads/2020/09/tech-startup-testimonial-reviews-ava-3.jpg',
    clientName: 'EMILY WATSON',
    clientDesignation: '',
  },
  {
    clientFeedback:
      '"They helped me in e-commerce website really professional team."',
    clientPicture:
      'https://tecknovation.com/wp-content/uploads/2020/09/tech-startup-testimonial-reviews-ava-1.jpg',
    clientName: 'TINA OLIVER',
    clientDesignation: '',
  },
  {
    clientFeedback:
      '"Thanks Tecknovation for super impressive marketing and application that you have provided me with. I recommend everyone to use their services."',
    clientPicture:
      'https://tecknovation.com/wp-content/uploads/2020/09/tech-startup-testimonial-reviews-ava-2.jpg',
    clientName: 'JENNIFER DUNN',
    clientDesignation: '',
  },
  {
    clientFeedback:
      '"Keep it up Wolftechs. Where Innovation meets Excellence . Thank you for the trading application."',
    clientPicture:
      'https://tecknovation.com/wp-content/uploads/2020/09/tech-startup-testimonial-reviews-ava-6.jpg',
    clientName: 'Diana George',
    clientDesignation: '',
  },
];

export const aboutUsPageText = [
  {
    text: 'Meet the team who make us today!',
    variant: 'p',
    component: 'div',
    style: {
      color: '#000',
      textAlign: {xs: 'center', md: 'left'},
      fontSize: {xs: '37px', md: '57px'},
      fontWeight: '700',
      marginBottom: '25px',
    },
  },
  {
    text: 'This is team of most expert and experience member in Our Company. They are the most professional members of our Company.',
    variant: 'p',
    component: 'div',
    style: {color: '#000', textAlign: 'left', margin: {xs: '15px', md: 0}},
  },
];

export const contactText = [
  {
    text: 'Contact Us at:',
    variant: 'p',
    component: 'div',
    style: {
      color: '#fff',
      textAlign: 'center',
      fontSize: {xs: '27px', md: '37px'},
      fontWeight: '700',
      padding: '15px',
    },
  },
  {
    text: 'support@tecknovation.com',
    variant: 'p',
    component: 'div',
    style: {color: '#fff', textAlign: 'center', margin: {xs: '15px', md: 0}},
  },
];

export const copyrightText = {
  text: `Copyright © ${currentYear} Tecknovation`,
  variant: 'p',
  component: 'div',
  style: {
    fontSize: '13px',
    fontWeight: 500,
    marginTop: '10px',
    color: '#fff',
    textAlign: 'center',
  },
};

export const footerAddressText = {
  text: '2B 55th Avenue Raiwand Road, Lahore Pakistan',
  variant: 'p',
  component: 'div',
  style: {fontSize: {xs: '13px', sm: '23px'}, fontWeight: 500, color: '#fff'},
};
