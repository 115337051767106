import React from 'react';
import {Grid} from '@mui/material';
import SingleCard from './SingleCard';
import {cardsText} from '../commonvariables';

const CardsContainer = () => {
  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: '80px',
        paddingX: {md: '80px'},
        backgroundColor: '#DDDDDD',
      }}
    >
      {cardsText.map((card) => {
        return (
          <Grid item xs={10} sm={5} md={3} sx={{margin: '15px'}}>
            <SingleCard
              heading={card.heading}
              description={card.description}
              link={card.link}
              buttonText={card.buttonText}
              type={card.type}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardsContainer;
