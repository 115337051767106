import React, {useState} from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';
import Image from './Image';
import {logo} from '../commonvariables';

export default function Navbar() {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  const list = (anchor) => (
    <Box
      sx={{width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '10px',
          backgroundColor: '#B3005E',
          borderRadius: '0 0 15px 15px',
        }}
      >
        <Typography variant="h6" component="div" color={'#fff'}>
          Tecknovation
        </Typography>
      </Box>
      <List>
        {['Home', 'Portfolio', 'Services', 'About Us', 'Get Consultation'].map(
          (text, index) => (
            <Link
              to={`/${
                index === 0
                  ? ''
                  : index === 1
                  ? 'portfolio'
                  : index === 2
                  ? 'services'
                  : index == 3
                  ? 'aboutUs'
                  : index == 4 && 'contactUs'
              }`}
              style={{textDecoration: 'none', color: '#000'}}
              key={text}
            >
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    {index === 0 ? (
                      <HomeIcon />
                    ) : index === 1 ? (
                      <BusinessCenterIcon />
                    ) : index === 2 ? (
                      <SettingsSuggestIcon />
                    ) : index == 3 ? (
                      <InfoIcon />
                    ) : (
                      index == 4 && <ContactPageIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Link>
          )
        )}
      </List>
    </Box>
  );
  return (
    <AppBar position="static" sx={{backgroundColor: '#9E4784'}}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: {xs: 'center', md: 'space-around'},
        }}
      >
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            display: {xs: 'block', sm: 'none'},
            position: 'absolute',
            left: '7%',
            backgroundColor: '#F0EEED',
            color: '#1976d2',
            borderRadius: '15%',
            paddingX: '7px',
            paddingY: '5px',
          }}
          onClick={toggleDrawer('left', true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor={'left'}
          open={state['left']}
          onClose={toggleDrawer('left', false)}
          sx={{display: {xs: 'block', sm: 'none'}}}
        >
          {list('left')}
        </Drawer>
        <Image source={logo} alt="logo" height="100" />
        <Box sx={{display: 'flex'}}>
          <Link to="/" style={{textDecoration: 'none', color: '#fff'}}>
            <Button color="inherit" sx={{display: {xs: 'none', sm: 'block'}}}>
              Home
            </Button>
          </Link>
          <Link to="/portfolio" style={{textDecoration: 'none', color: '#fff'}}>
            <Button color="inherit" sx={{display: {xs: 'none', sm: 'block'}}}>
              Portfolio
            </Button>
          </Link>
          <Link to="/services" style={{textDecoration: 'none', color: '#fff'}}>
            <Button color="inherit" sx={{display: {xs: 'none', sm: 'block'}}}>
              Services
            </Button>
          </Link>
          <Link to="/aboutUs" style={{textDecoration: 'none', color: '#fff'}}>
            <Button color="inherit" sx={{display: {xs: 'none', sm: 'block'}}}>
              About Us
            </Button>
          </Link>
        </Box>
        <Link to="/contactUs" style={{textDecoration: 'none', color: '#fff'}}>
          <Button
            sx={{
              color: '#fff',
              display: {xs: 'none', sm: 'block'},
            }}
          >
            Get Consultation
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
}
