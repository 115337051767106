import React from 'react';
import {Grid} from '@mui/material';
import Text from './../components/Text';
import LottiePlayer from '../components/LottiePlayer';
import {moreInfoText, infoLottie} from '../commonvariables';

const MoreInfo = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: '#66347F',
        alignItems: 'center',
        paddingBottom: '45px',
        borderRadius: '50px 50px 0 0',
      }}
    >
      <Grid item xs={12} md={6}>
        <LottiePlayer lottie={infoLottie} />
      </Grid>
      <Grid item xs={12} md={5}>
        {moreInfoText.map((text) => {
          return (
            <Text
              variant={text.variant}
              component={text.component}
              text={text.text}
              style={text.style}
              key={text.text}
            />
          );
        })}
      </Grid>
      <Grid item md={1} />
    </Grid>
  );
};

export default MoreInfo;
